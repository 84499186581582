<template>
    <div class="user-details-form slide-in-right">
        <div class="user-details-form-title">
            <h2>פרטי מזמין</h2>
            <div @click="$emit('back')" class="exit-btn">
                <i class="material-icons" style="color: #fff;">arrow_back</i>
            </div>
        </div>
        <div class="user-details-form-content">
            <div class="user-details-form-content-field">
                <h2>שם החברה <span style="color: red;">*</span></h2>
                <el-input v-model="user_data.company" placeholder="שם החברה" />
            </div>
            <div class="user-details-form-content-field">
                <h2>ח.פ/עוסק מורשה <span style="color: red;">*</span></h2>
                <el-input type="tel" v-model="user_data.company_id" placeholder="ח.פ/עוסק מורשה" />
            </div>
            <div class="user-details-form-content-field">
                <h2>שם איש קשר <span style="color: red;">*</span></h2>
                <el-input v-model="user_data.contact_name" placeholder="שם איש קשר" />
            </div>
            <div class="user-details-form-content-field">
                <h2>טלפון <span style="color: red;">*</span></h2>
                <el-input type="tel" v-model="user_data.phone" placeholder="טלפון" />
            </div>
            <div class="user-details-form-content-field">
                <h2>דוא"ל <span style="color: red;">*</span></h2>
                <el-input type="email" v-model="user_data.email" placeholder="כתובת אימייל" />
            </div>
        </div>
        <div class="user-details-form-footer">
            <el-button @click="handle_submit" style="width: 80%;" type="success">סיום</el-button>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import {slide_pop_error,alert} from '../../Methods/Msgs'

    export default {
        emits:['back','submit'],
        setup(_,{emit}){

            const error_msg = ref('')

            const user_data = ref({
                company:'',
                company_id:'',
                contact_name:'',
                phone:'',
                email:'',
            })

            const validation = () => {
                for (const [key, value] of Object.entries(user_data.value)) {
                    
                    switch (key) {
                        case 'company':
                            if(!value) {
                                error_msg.value="עליך להזין את שם החברה!"
                                return false
                            }
                            break

                        case 'company_id':
                            if(!value) {
                                error_msg.value="עליך להזין ח.פ/עוסק מורשה של החברה!"
                                return false
                            }
                            break

                        case 'contact_name':
                            if(!value) {
                                error_msg.value="עליך להזין שם איש קשר!"
                                return false
                            }
                            break
                        case 'phone':
                            if(!value) {
                                error_msg.value="עליך להזין מספר טלפון!"
                                return false
                            }
                            if(!validateIsraeliMobilePhone(value)) {
                                error_msg.value="עליך להזין מספר טלפון תקין!"
                                return false
                            }
                            break
                        case 'email':
                            if(!value) {
                                error_msg.value="עליך להזין כתובת אימייל!"
                                return false
                            }
                           
                            if(!validateEmail(value)) {
                                error_msg.value="עליך להזין כתובת אימייל תקינה!"
                                return false
                            }
                            break
                            
                    }
                }
                return true
            }

            const handle_submit = () => {
                if(!validation()){
                    slide_pop_error(error_msg.value)
                }else{
                    alert('success','ההזמנה נשלחה בהצלחה')
                    emit('submit',user_data.value)
                }
            }

            function validateEmail(email) {
                const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                console.log(regex.test(email));
                return regex.test(email);
            }

            function validateIsraeliMobilePhone(number) {
                const regex = /^05[0-8]\d{7}$/;
                return regex.test(number);
            }

            return{
                handle_submit,
                user_data,
                error_msg,
                validateEmail,
            }
        }
    }
</script>

<style scoped>
    .user-details-form{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #fff;
        display: flex;
        flex-direction: column;
    }
    .user-details-form-title,.user-details-form-footer{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .user-details-form-content{
        width: 100%;
        flex: 1;
        overflow-y: auto;
    }
    .user-details-form-content-field{
        width: 100%;
        padding: 5px;
        margin-bottom: 15px;
    }

    .slide-in-right {
	    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
                transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
                transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }

    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }

  
</style>